<template>
    <div class="location-not-supported">
        <div class="wrapper relative">
            <transition name="zoom-fade" appear>
                <div v-if="state === 'success'" class="state-success">
                    <h2>
                        <icon name="smiley-happy" />
                        <span>
                            Danke,<br />
                            wir melden uns bei Dir!
                        </span>
                    </h2>

                    <butty class="" @click="$emit('close')">Schließen</butty>
                </div>
            </transition>

            <div :class="['state-edit', { hide: state !== 'edit' }]">
                <header>
                    <h2 class="title">
                        Leider holen wir (noch) nicht in der eingegebenen PLZ
                        ab.
                    </h2>
                </header>
                <div v-if="b2bCustomerType === 'industrial'">
                    <p>
                        Gerne können Sie sich aber persönlich per E-Mail an
                        kundenservice@recyclehero.de oder telefonisch unter der
                        <br />
                        +49 40 228 651 610 bei unserem Kundenserservice
                        melden.<br />
                        Wir versuchen die Abholung anderweitig zu veranlassen.
                    </p>
                    <div class="mt-12"></div>
                    <butty
                        :to="{ name: 'index' }"
                        class="accent"
                        @click="onClickCloseModal"
                        >Zur Startseite</butty
                    >
                </div>
                <div v-else>
                    <p
                        v-if="
                            baseareaName !== '' &&
                            baseareaName !== undefined &&
                            supportedProducts.length === 0
                        "
                        class="mb-3"
                    >
                        Wir sind zwar schon in
                        <span class="font-bold">{{ baseareaName }}</span> am
                        Start, leider noch nicht in deiner Nähe.
                    </p>

                    <div v-if="supportedProducts.length > 0">
                        <span class="font-bold text-lg"
                            >Aber gute Nachrichten!</span
                        >
                        <p class="mb-2 mt-4">Wir holen schon</p>
                        <div
                            v-for="(
                                product, index
                            ) in getReducedSupportedProducts(supportedProducts)"
                            :key="product.id"
                        >
                            <p>
                                {{
                                    index + 1 ===
                                        getReducedSupportedProducts(
                                            supportedProducts,
                                        ).length &&
                                    getReducedSupportedProducts(
                                        supportedProducts,
                                    ).length !== 1
                                        ? 'und'
                                        : ''
                                }}
                                <linky
                                    text-link
                                    :to="getRouterPath(product)"
                                    @click="onClickCloseModal"
                                >
                                    {{ getProductLabel(product) }} </linky
                                >{{
                                    index <
                                    getReducedSupportedProducts(
                                        supportedProducts,
                                    ).length -
                                        1
                                        ? ', '
                                        : ''
                                }}
                            </p>
                        </div>
                        <p class="mt-2 mb-4">bei dir ab.</p>
                        <p class="mb-2">
                            Du kannst dich natürlich gern in die Warteliste
                            eintragen und wir benachrichtigen dich gerne, sobald
                            es losgeht!
                        </p>
                    </div>

                    <p v-else class="mb-6">
                        Du kannst dich in die Warteliste eintragen und wir
                        benachrichtigen dich gerne, sobald es losgeht!
                    </p>

                    <FormKit
                        v-model="formData"
                        type="form"
                        :actions="false"
                        :errors="errors"
                        @submit="handleSubmit"
                    >
                        <FormKit
                            type="email"
                            name="email"
                            label="E-Mail"
                            placeholder=""
                            validation="required|email"
                        />
                        <div class="approvals">
                            <div class="dataProtectionText">
                                recyclehero (HC Sustainable Logistics GmbH)
                                verpflichtet sich, deine Privatsphäre zu
                                schützen und zu respektieren. Wir verwenden
                                deine persönlichen Daten nur zur Verwaltung
                                deines Kontos und zur Bereitstellung der von dir
                                angeforderten Produkte und Dienstleistungen.
                            </div>
                            <!-- some space -->
                            <div class="mt-2"></div>

                            <FormKit
                                type="checkbox"
                                label="Datenschutzerklärung"
                                name="dataProtection"
                                validation="accepted"
                                :validation-messages="{
                                    accepted:
                                        'Bitte die Datenschutzerklärung akzeptieren!',
                                }"
                                :sections-schema="{
                                    label: {
                                        children: [
                                            'Ich habe die ',
                                            {
                                                $el: 'a',
                                                attrs: {
                                                    href: '/datenschutz',
                                                },
                                                children:
                                                    'Datenschutzerklärung',
                                            },
                                            ' gelesen und akzeptiere sie. *',
                                        ],
                                    },
                                }"
                            />

                            <!-- some space -->
                            <div class="mt-8"></div>
                            <div class="dataProtectionText">
                                Hier gibt's regelmäßig Wissen, News und
                                Inspiration von recyclehero.
                            </div>
                            <!-- some space -->
                            <div class="mt-2"></div>
                            <FormKit
                                type="checkbox"
                                label="Bleib' auf dem Laufenden und abonniere unsere monatlichen Good News. Du kannst Dich jederzeit wieder abmelden."
                                name="newsletter"
                            />
                        </div>

                        <div class="row">
                            <butty class="small" type="submit"
                                >Benachrichtigt mich 💚</butty
                            >
                        </div>
                    </FormKit>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import useOrderStore from '~/stores/order.js'

import { EnumsLegacy } from '~~/config/Enums'
import useOrderStore from '@/stores/order'
import { replaceUmlauts, routePathExists, reduceURL } from '@/utils/utils'

export default {
    inject: ['loading'],
    props: {
        zipCode: { type: String, default: '' },
        customerGroup: { type: String, default: '' },
    },
    emits: ['close', 'result'],
    setup() {
        const orderStore = useOrderStore()

        return {
            orderStore,
        }
    },

    data() {
        return {
            formData: {
                email: '',
                dataProtection: false,
                newsletter: false,
            },
            errors: [],
            state: 'edit',
            baseareaName: this.orderStore.baseareaName,
            productTypes: this.orderStore.productTypes,
            supportedProducts: this.orderStore.supportedProducts,
            b2bCustomerType: this.orderStore.settings.b2bCustomerType,
        }
    },

    methods: {
        handleSubmit() {
            const vm = this

            if (vm.loading()) {
                return false
            }

            vm.loading(true)
            vm.errors = []

            const currentUrl = window.location.href

            // TODO:
            $fetch
                .raw(
                    vm.$nuxt.$config.public.sources.api.url +
                        '/order/zipcodereminder',
                    {
                        method: 'POST',
                        body: {
                            zipcode: vm.zipCode,
                            email: vm.formData.email,
                            segment:
                                EnumsLegacy.customerGroupToType[
                                    vm.customerGroup
                                ]?.name || '',
                            newsletter: vm.formData.newsletter,
                            fromUrl: currentUrl,
                        },
                    },
                )
                .then((response) => {
                    vm.loading(false)
                    if (response?.status !== 200) {
                        throw new Error('setting reminder failed')
                    }

                    // show thank you
                    vm.state = 'success'

                    vm.formData.email = ''
                })
                .catch((error) => {
                    vm.loading(false)

                    this.$bugsnag.notify(error, (event) => {
                        event.context = 'location-not-supported-modal.fetch'
                    })

                    vm.state = 'edit'
                    vm.errors = [
                        'Etwas ist schiefgelaufen!',
                        'Wir wurden informiert und kümmern uns darum.',
                    ]
                })
        },

        getRouterPath(product) {
            let routePathToNavigateTo = product.landingPageUrl
            const allAvailableRoutes = this.$router.getRoutes()

            if (product.recyclables === 'clothes') {
                const baseareaPathPart = replaceUmlauts(
                    this.baseareaName?.toLowerCase(),
                )

                // Not every basearea has a dedicated page for clothes
                // TODO: maybe it's a good idea that every basearea has a dedicated page for clothes
                if (baseareaPathPart) {
                    routePathToNavigateTo = `${product.landingPageUrl}/${baseareaPathPart}`
                }
            }

            // check if vue router knows this path
            // if not, reduce after every slash until it's known (at least "/")
            while (
                routePathExists(routePathToNavigateTo, allAvailableRoutes) ===
                false
            ) {
                routePathToNavigateTo = reduceURL(routePathToNavigateTo)
            }

            return routePathToNavigateTo
        },

        /**
         * Reduce the supported products via the recyclables key.
         * There are e.g. two products for recyclabels "paper" - a bag and a box, but we need only one of them.
         * @param {Array<object>} supportedProducts
         */
        getReducedSupportedProducts(supportedProducts) {
            const reducedSupportedProducts = []

            supportedProducts.forEach((product) => {
                if (
                    !reducedSupportedProducts.some(
                        (reducedProduct) =>
                            reducedProduct.recyclables === product.recyclables,
                    )
                ) {
                    reducedSupportedProducts.push(product)
                }
            })

            return reducedSupportedProducts
        },

        getProductLabel(product) {
            // Special case for clothes campaign (has the same recyclables as clothes, but is a different product type)
            if (product.recyclables === 'clothes') {
                if (product.productType === 'clothes-campaigns') {
                    return Enums.recyclablesLabels[product.productType]
                }
            }

            return Enums.recyclablesLabels[product.recyclables]
        },

        onClickCloseModal() {
            this.$emit('close')
            this.orderStore.$patch({
                settings: { b2bCustomerType: Enums.b2bCustomerType.general },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.location-not-supported {
    max-width: 36rem;

    .title {
        margin-bottom: 1.5rem;
    }
}

.approvals {
    margin-top: 1rem;

    :deep(.formkit-outer) {
        .formkit-decorator {
            margin-right: 1rem;
        }

        .formkit-label {
            a {
                text-decoration: underline;
            }

            @apply text-sm;
        }
    }

    .dataProtectionText {
        @apply text-xs;
    }
}

.state-edit {
    position: relative;
    z-index: 5;
    opacity: 1;
    transition: opacity 0.25s;

    &.hide {
        opacity: 0;
    }
}

.state-success {
    position: absolute;
    @apply inset-0;
    z-index: 10;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    h1 {
        text-align: left;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    .icon {
        display: block;
        width: 4rem;
        margin-right: 1.5rem;
        color: theme('colors.slate.500');
    }
}

:deep(.formkit-form) {
    .row {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;

        [data-type='email'] {
            width: 100%;

            .formkit-wrapper {
                max-width: none;
            }
        }

        .butty {
            width: 100%;
            margin-top: 1rem;
        }
    }
}

@screen sm {
    .location-not-supported {
        .title {
            margin-bottom: 2rem;
        }
    }

    :deep(.formkit-form) {
        .row {
            flex-flow: row nowrap;

            [data-type='email'] {
                flex: auto 1 0;
                width: auto;
                margin-right: 1rem;
            }

            .butty {
                flex: auto 1 0;
                width: auto;
                margin-top: 1.5rem;
            }
        }
    }
}
</style>
